<template>
  <Modal
    title="ÉDITION DU PROFIL"
    :open="open"
    headerColor="red"
    :headerIcon="require('@/assets/icons/utils/compte/person.svg')"
    size="xs"
    :keepratio="true"
    @close-modal="close"
  >
    <template slot="modal_content">
      <form @submit.prevent="submit" id="form">
        <div v-if="!validationNumero" class="form-group mb-2">
          <input
            type="tel"
            pattern="[0-9]"
            v-model.trim="$v.userInfoValidation.numero.$model"
            :error="$v.userInfoValidation.numero.$error"
            placeholder="Portable"
            class="font pb-1 pl-2 focus:outline-none"
          />
          <DoiText
            v-if="
              !$v.userInfoValidation.numero.required &&
              $v.userInfoValidation.numero.$error
            "
            italic
            :textColor="0"
            :fontSize="1"
            >Ce champ est requis</DoiText
          >
        </div>
        <div v-if="validationCodeNumber" class="form-group mb-2">
          <input
            type="tel"
            minlength="6"
            maxlength="6"
            pattern="[0-9]"
            required
            v-model="$v.codeInfo.code.$model"
            :error="$v.codeInfo.code.$error"
            placeholder="Code de vérification"
            class="font pb-1 pl-2 focus:outline-none"
          />
          <DoiText
            v-if="!$v.codeInfo.code.required && $v.codeInfo.code.$error"
            italic
            :textColor="0"
            :fontSize="1"
            >Ce champ est requis</DoiText
          >
        </div>
      </form>
    </template>

    <template slot="modal_footer">
      <DoiText :textColor="1" :fontSize="3" class="pl-10">{{
        message
      }}</DoiText>
      <div class="flex p-8 justify-center">
        <DoiButton
          @click="close"
          class="w-6/12 mr-4 tracking-widest"
          :fontFamily="6"
          :bgColor="2"
          :bgHover="3"
          >ANNULER</DoiButton
        >
        <button
          v-if="!validationNumero"
          @click="validationPhoneNumber"
          class="button red w-6/12"
          type="submit"
        >
          <DoiText :fontFamily="6" class="tracking-widest"
            >VALIDER<br />
            MON NUMÉRO</DoiText
          >
          <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
        </button>
        <button
          @click="validationCode"
          v-if="validationCodeNumber"
          class="button red w-6/12"
          type="submit"
        >
          <DoiText :fontFamily="6" class="tracking-widest"
            >VALIDER<br />
            MON CODE</DoiText
          >
          <DoiSpinner :show="showSpinner" :color="1" class="ml-3" />
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/molecules/Modal";

import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import {
  setPhoneNumberV2,
  setValidationPhoneNumberV2,
} from "@/api/ValidationService";

export default {
  name: "ProfilTelEdit",
  data() {
    return {
      showSpinner: false,
      validationNumero: false,
      validationCodeNumber: false,
      message: "",
      userInfoValidation: {
        numero: "",
      },
      userInfoCopy: {
        sNom: "",
        sPrenom: "",
        sEmail: "",
        sPortable: "",
      },
      codeInfo: {
        code: "",
      },
    };
  },
  components: { Modal },
  props: {
    userInfo: {
      type: Object,
      default: {},
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    userInfoValidation: {
      numero: { required },
    },
    codeInfo: {
      code: { required },
    },
  },
  created() {
    this.copyUser();
  },
  methods: {
    ...mapActions("UserModule", ["SET_PROFIL_INFO"]),
    close() {
      this.$emit("close");
    },
    copyUser() {
      this.userInfoValidation.numero = this.userInfo.portable;
    },
    submit() {
      this.userInfoCopy.sNom = this.userInfo.nom;
      this.userInfoCopy.sPrenom = this.userInfo.prenom;
      this.userInfoCopy.sEmail = this.userInfo.userMail;
      this.userInfoCopy.sPortable = this.userInfoValidation.numero;
      this.showSpinner = true;
      this.$v.$touch();
      if (this.$v.$invalid) this.showSpinner = false;
      else this.setUserData();
    },
    async setUserData() {
      try {
        await this.SET_PROFIL_INFO(this.userInfoCopy);
        this.close();
      } catch (err) {
      } finally {
        this.showSpinner = false;
      }
    },
    async validationPhoneNumber() {
      // TEMPORAIRE, SANS ENVOI DE SMS À CAUSE DE MAILJET
      const data = {
        sNom: this.userInfo.nom,
        sPrenom: this.userInfo.prenom,
        sEmail: this.userInfo.userMail,
        numero: this.userInfoValidation.numero,
      };

      await setPhoneNumberV2(data).then(async () => {
        this.telMessageSuccess =
          "Votre numéro de téléphone a bien été validé par notre système.";
        this.telMessageError = "";
        this.numero = this.userInfo.numero;
        await this.submit();
        setTimeout(() => {
          this.$router.go();
        }, 2000);
      });

      // FONCTIONNEMENT NORMAL, AVEC ENVOI DE SMS
      // this.message = "";
      // this.validationCodeNumber = true;
      // this.validationNumero = true;
      // setPhoneNumberV2(this.userInfoValidation);
    },
    validationCode() {
      this.showSpinner = true;
      setValidationPhoneNumberV2(this.codeInfo).then((response) => {
        if (response.bSucces) {
          this.message =
            "Votre numéro de téléphone a bien été validé par notre système.";
          this.submit();
        } else {
          this.message =
            "Votre code de validation est erroné, veuillez recommencer l'opération.";
          this.validationNumero = false;
          this.validationCodeNumber = false;
        }
      });
    },
  },
  watch: {
    open(value) {
      if (value) this.copyUser();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.font {
  border-bottom: 2px solid $doi--color-color-lighter4;
  font-family: $doi--text-font-family5;
}
</style>
